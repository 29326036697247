import React from 'react';

export const FullScreenIframe = ({ url, title }) => {
  return (
    <iframe
      src={url}
      style={{
        width: '100%',
        height: '100vh',
        border: 'none',
      }}
      title={title}
    />
  );
};
