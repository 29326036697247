import React from 'react';
import { Hero } from '../components/Hero/Hero';
import { useNavigate, useLocation } from 'react-router-dom';

export const Masterclass = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const magnes = searchParams.get('magnes');

  React.useEffect(() => {
    const button = document.querySelector('.cta-button');

    const addWobble = () => {
      button.classList.add('wobble');
      setTimeout(() => {
        button.classList.remove('wobble');
      }, 500);
    };

    // Wobble every 3 seconds
    const intervalId = setInterval(addWobble, 3000);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const masterclassButton = (
    <button
      className="cta-button"
      style={{ marginBottom: '20px !important' }}
      onClick={() => {
        document.querySelector('.pricing-section').scrollIntoView({
          behavior: 'smooth',
        });
      }}
    >
      TAK, JESTEM GOTOWA!
    </button>
  );

  const handleCheckout = () => {
    const checkoutPath = '/masterclass/checkout';
    // If we have an magnes, append it to the checkout URL
    if (magnes) {
      navigate(`${checkoutPath}?magnes=${encodeURIComponent(magnes)}`);
    } else {
      navigate(checkoutPath);
    }
  };

  return (
    <>
      <Hero
        title="Sekrety|Kobiecego|Magnetyzmu"
        tag="Premiera 4 stycznia 2025"
        button={masterclassButton}
      />

      <div className="info-section">
        <div className="accent-text" style={{ fontSize: '45px' }}>
          Odkryj sekretny wymiar...
        </div>

        <div className="main-heading" style={{ fontSize: '31px' }}>
          PEWNOŚCI SIEBIE I<br /> KOBIECEGO
          <br /> MAGNETYZMU
        </div>

        <div className="regular-text">Odważ się sięgać po więcej...</div>
        <br />
        <div className="regular-text">
          I PRZYCIĄGAĆ mężczyzn, <br className="xs-break" /> którzy będą Cię
          <br />
          <span className="strong">ROZPIESZCZAĆ I ADOROWAĆ</span>
          <br /> na absolutnie nowym poziomie,
          <br /> jakiego jeszcze nie znałaś!
        </div>
        <br />

        <div className="regular-text">
          To czas, byś zanurzyła się w <br />
          swojej <span className="strong">KOBIECOŚCI...</span>
        </div>
        <br />
        <div className="regular-text">
          i zaczęła dostawać wszystko,
          <br /> czego pragniesz!
        </div>

        <div className="accent-text">Jesteś gotowa?</div>

        <div className="regular-text">
          Mężczyźni czują, jaką energią emanujesz
          <br />
          <br /> Tutaj nie ma miejsca na <br />
          <span style={{ fontStyle: 'italic' }}>
            „fake it till you make it”!
          </span>
          <br />
          <br /> Otwórz się na nowy wymiar
          <br />
          <span className="strong"> MAGNETYCZNEJ KOBIECOŚCI </span>
          <br />
          dzięki której staniesz się kobietą, <br />
          obok której nie da się przejść obojętnie!
        </div>
        <div className="regular-text">
          <br /> Z lekkością, przyjemnością i radością
        </div>
        <div className="regular-text">
          <br />
          Tak! To możliwe! <br />
          <br /> Pokażę Ci, jak...
        </div>
        <br />
      </div>

      <div className="bg-section">
        <div className="accent-text">Twoja Transformacja</div>

        <div className="main-heading" style={{ marginBottom: '10px' }}>
          Od niepewności do <br />
          magnetycznej kobiecości
        </div>

        <div className="benefit-group">
          <div className="divider">
            <span>✿</span>
          </div>
          <div className="regular-text">
            Obudzisz w sobie <span className="strong">głęboką MOC</span>
            <br />
            która pozwoli Ci zrozumieć, jak sprawić,
            <br /> by mężczyzna zachwycił się Tobą nie tylko
            <br /> na początku, ale stale zabiegał o Ciebie
            <br /> na każdym etapie Waszej znajomości
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>
        <div className="benefit-group">
          <div className="regular-text">
            Staniesz się kobietą, która, ma
            <span className="strong"> "TO COŚ"</span> <br />
            jest w pełni świadoma swojej wartości <br />
            Twoja pewność siebie sprawi, <br />
            że wymarzeni mężczyźni <br />
            sami będą dążyli do poznania Ciebie!
          </div>

          <div className="divider">
            <span>✿</span>
          </div>
        </div>
        <div className="benefit-group">
          <div className="regular-text">
            Dowiesz się, jak wyzwolić swoją
            <span className="strong"> BOGINIĘ</span>
            <br /> i zacząć przyciągać mężczyzn, <br />
            którzy będą doceniać Twoją wartość
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
          <div className="benefit-group">
            <div className="regular-text">
              Odkryjesz, jak myśli i działa kobieta
              <span className="strong"> PEŁNA MOCY</span>
              <br /> poznasz tajemnice, które pozwolą Ci
              <br /> zwiększyć pewność siebie i wyznaczać standardy,
              <br /> które przyciągną do Ciebie wszystko, czego pragniesz
            </div>
            <div className="divider">
              <span>✿</span>
            </div>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Nauczysz się tworzyć relację, w której
            <br /> pozwolisz mężczyźnie zaopiekować się Tobą czując dogłębne{' '}
            <span className="strong">bezpieczeństwo</span> <br />
            wiedząc, że on pragnie tylko Ciebie
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Przypomnisz sobie, że <span className="strong">ZASŁUGUJESZ</span>
            <br />
            na wszytko co najlepsze i z lekkością
            <br /> otworzysz się na otrzymywanie
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Odzyskasz <span className="strong">RÓWNOWAGĘ </span>
            tworząc związek,
            <br /> w którym, nie musisz walczyć o uwagę, <br />
            wszystko dzieje się
            <span className="strong"> NATURALNIE</span> i w{' '}
            <span className="strong">HARMONII</span>
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Odkryjesz, jak komunikować się w <br />
            <span className="strong">ŻEŃSKIEJ ENERGII</span> <br />
            tak, aby on sam chciał spełniać
            <br /> wszystkie Twoje potrzeby
            <br /> bez Twojego proszenia
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>

        <div className="benefit-group">
          <div className="regular-text">
            Nauczysz się jak <span className="strong">INSPIROWAĆ</span> <br />{' '}
            mężczyznę aby pragnął, <br />
            tworzyć z Tobą <br />
            przyszłość, o której marzyłaś
          </div>
          <div className="divider">
            <span>✿</span>
          </div>
        </div>
      </div>

      <div className="transformation-benefits">
        <div className="mystical-container">
          <div className="main-heading">DLACZEGO TWORZĘ</div>
          <div className="accent-text"> ten Masterclass</div>

          <div className="regular-text">
            Kiedy 4,5 roku temu, rozstałam się z <br />
            partnerem po miesiącach
            <br /> WALKI o nasz związek...
            <br /> nie wiedziałam, że to będzie
            <br />
            <span className="strong">POCZĄTEK ZMIANY,</span> która na zawsze
            <br />
            odmieni mnie jako KOBIETĘ...
            <br />
            <br /> Poznałam wtedy swoją mentorkę, <br /> która pokazała mi, jak
            <span className="strong"> OBUDZIĆ,</span> <br />
            potężną kobiecą moc, <br /> którą MIAŁAM już w sobie
            <br />
            <br /> Odkryłam <span className="strong">SEKRETY magnetyzmu</span>
            <br /> które sprawiły, że stałam
            <br /> się nieodparta dla mężczyzn <br /> <br />I wtedy – pojawił
            się ON...
            <br /> <br />
            Moja ROZBUDZONA KOBIECOŚĆ sprawiła, że
            <br />
            <span className="strong">OSZALAŁ</span> na moim punkcie <br />
            Zaczął mnie adorować, rozpieszczać <br />i spełniać moje marzenia….
            <br />
            <br />
            Dziś jesteśmy po ślubie - na Hawajach,
            <br /> w miejscu, które było moim{' '}
            <span className="strong">MARZENIEM</span> <br />
            od kiedy byłam małą dziewczynką!
            <br />
            <br />
            Nauczenie się tej <span className="strong">JEDNEJ rzeczy</span>{' '}
            sprawiło
            <br /> ze moje życie zmieniło się
            <br />
            <br /> nie do poznania...
            <br /> <br />
            Tymi <span className="strong">MAGNETYCZNYMI </span>zasadami
            <br /> i mindsetem, który za tym stoi, <br />
            podzielę się z Tobą na tym masterclassie
          </div>
          <span
            className="accent-text"
            style={{ float: 'right', marginTop: '30px' }}
          >
            - Kate Jaskólska
          </span>
        </div>
      </div>

      <div className="bg-section2">
        <div className="mystical-container">
          <div
            className="benefits-list"
            style={{ marginBottom: '40px !important' }}
          >
            <div className="main-heading">
              Czy TEN MASTERCLASS
              <br /> jest dla Ciebie? <br />
            </div>
            <div className="accent-text" style={{ lineHeight: '1' }}>
              Tak, jeśli pragniesz...
            </div>
          </div>

          <div className="regular-text">
            Dołączyć do niewielkiego odsetka
            <span className="strong"> MAGNETYCZNYCH KOBIET</span>, które
            przyciągają zaangażowanych mężczyzn, którzy je{' '}
            <span className="strong">adorują</span>,{' '}
            <span className="strong">rozpieszczają</span> i{' '}
            <span className="strong">spełniają</span> ich marzenia, tworząc
            relacje pełne lekkości, miłości do siebie i harmonii...
            <br />
            <br />
          </div>
          <div className="regular-text">
            Masz dość mężczyzn, którzy po pierwszej fazie adoracji zaczynają się
            oddalać, budząc w Tobie ogromny lęk i pustkę.
            <br />
            <br /> Z zazdrością patrzysz na inne kobiety, które są{' '}
            <span className="strong">rozpieszczane</span> i{' '}
            <span className="strong"> adorowane</span> a w głebi duszy czujesz,
            że też na to <span className="strong">zasługujesz</span>
          </div>
        </div>
      </div>

      <div className="pricing-section">
        <div className="pricing-box">
          <div className="pricing-header">
            <div className="accent-text">Masterclass</div>
          </div>

          <div className="price-container">
            <div className="price-column">
              <div className="current-price">555 ZŁ</div>
            </div>
          </div>

          <div className="promo-banner">
            <span className="promo-icon">⭐</span>
            <br />
            <br />
            <span className="promo-text">
              Oferta w MAGNETYCZNEJ cenie ważna tylko do KOŃCA ROKU!
            </span>
            <br />
          </div>

          <ul className="pricing-features">
            <li>
              💎 ponad 2-godzinne spotkanie na żywo z ŻEŃSKĄ ENERGIĄ, odbędzie
              się 4 stycznia o godzinie 10:00
              <br />
              <small>(+ dożywotni dostęp do nagrania)</small>
            </li>
            <li>
              💋 Dostęp do zamkniętej grupy na Facebook, NAJLEPSZE towarzystwo
              najbardziej MAGNETYCZNYCH kobiet jakie spotkasz w Internecie
              (serio, Kobiety w Mocy to ZŁOTO warte każdych pieniędzy!)
            </li>
            <li>
              🎁 PREZENT! wizualizacja wymarzonego związku z sesją embodiment o
              wartości 222 zł
            </li>
          </ul>
          <button className="pricing-cta" onClick={handleCheckout}>
            TAK, CHCĘ ZMIENIĆ SWOJE ŻYCIE!
          </button>
        </div>
      </div>

      {/* <div className="pricing-section">
        <div className="pricing-box">
          <div className="pricing-header">
            <div className="accent-text">Masterclass + Konsultacja</div>
          </div>

          <div className="price-container">
            <div className="price-column">
              <div className="current-price">888 ZŁ</div>
              <div className="future-price">1111 ZŁ</div>
              <div className="price-label">Cena od stycznia 2024</div>
            </div>
          </div>

          <div className="promo-banner">
            <span className="promo-icon">⭐</span>
            <span className="promo-text">
              Promocja ważna tylko do końca roku!
            </span>
            <span className="promo-icon">⭐</span>
          </div>

          <ul className="pricing-features">
            <li>
              nieograniczony dostęp do nagrania wideo i audio Masterclassu
            </li>
            <li>dostęp do zamkniętej społeczności Magnetycznych Kobiet</li>
            <li>magnetyczna konsultacja 1:1 90 min o wartości 500 zł</li>
          </ul>
          <button className="pricing-cta">Wybieram ten pakiet</button>
        </div>
      </div> */}
    </>
  );
};
