import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Masterclass } from './masterclass/Masterclass';
import { Checkout } from './checkout/Checkout';
import { CompletePage } from './checkout/CompletePage';
import { FullScreenIframe } from './components/FullScreenIframe';
import { WaitingList } from './waitingList/WaitingList';

import './App.css';
const AppContent = ({ stripePromise }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/quiz') {
      document.title = 'Kate Jaskólska • QUIZ';
    } else if (location.pathname === '/mentoring') {
      document.title = 'Kate Jaskólska • Mentoring';
    } else if (location.pathname.startsWith('/masterclass')) {
      document.title =
        'SEKRETY KOBIECEGO MAGNETYZMU • Masterclass • Kate Jaskólska';
    }
  }, [location]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <FullScreenIframe
            url="https://preview.mailerlite.io/preview/1181217/sites/138015431093913071/ankaYn?fresh=1"
            title="Kate Jaskólska • QUIZ"
          />
        }
      />
      <Route
        path="/afirmacje"
        element={
          <FullScreenIframe
            url="https://subscribepage.io/ankaYn"
            title="Kate Jaskólska • Afirmacje"
          />
        }
      />
      <Route
        path="/quiz"
        element={
          <FullScreenIframe
            url="https://preview.mailerlite.io/preview/1181217/sites/138015431093913071/ankaYn?fresh=1"
            title="Kate Jaskólska • QUIZ"
          />
        }
      />
      <Route
        path="/mentoring"
        element={
          <FullScreenIframe
            url="https://www.naffy.io/jaskolska.kate/konsultacje"
            title="Kate Jaskólska • Mentoring"
          />
        }
      />
      <Route path="/masterclass" element={<WaitingList />} />
      <Route path="/masterclass/presale" element={<Masterclass />} />
      <Route
        path="/masterclass/checkout"
        element={<Checkout stripePromise={stripePromise} />}
      />
      <Route
        path="/masterclass/complete"
        element={<CompletePage stripePromise={stripePromise} />}
      />
    </Routes>
  );
};

export const App = () => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    setStripePromise(
      loadStripe(
        'pk_live_51QJMs5GTSAt8aHE2g5XynM8aZAEOEI0hsbi8jomOE2mYFIJYSesz7FXKgmu9YeJKWSz0OFSzlfhOuMEr7d347pOa00RVScInzP'
      )
    );
  }, []);

  return (
    <BrowserRouter>
      <AppContent stripePromise={stripePromise} />
    </BrowserRouter>
  );
};
