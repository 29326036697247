export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    return {
      isValid: false,
      errorMessage: 'Adres e-mail jest wymagany.',
    };
  }
  if (!emailRegex.test(email)) {
    return { isValid: false, errorMessage: 'Nieprawidłowy adres e-mail.' };
  }
  return { isValid: true, errorMessage: '' };
};
