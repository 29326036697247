import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import './Checkout.css';

export const Checkout = ({ stripePromise }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [dpmCheckerLink, setDpmCheckerLink] = useState('');
  const [promoCode, setPromoCode] = useState('');

  useEffect(() => {
    // Get promo parameter from URL
    const searchParams = new URLSearchParams(window.location.search);
    const promo = searchParams.get('promo');
    setPromoCode(promo);

    // Determine productId based on promo parameter
    const productId = promo === '40' ? 'masterclassPromo' : 'masterclass';

    // Create PaymentIntent as soon as the page loads
    fetch('/api/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ productId }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        setDpmCheckerLink(data.dpmCheckerLink);
      });
  }, []);

  if (!clientSecret || !stripePromise) {
    return (
      <div className="checkout-loader-container">
        <div className="checkout-loader"></div>
      </div>
    );
  }

  return (
    <div className="stripe-checkout">
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret,
          appearance: {
            theme: 'stripe',
            variables: { colorPrimary: '#d67688' },
          },
          loader: 'auto',
          locale: 'pl',
        }}
      >
        <CheckoutForm dpmCheckerLink={dpmCheckerLink} promoCode={promoCode} />
      </Elements>
    </div>
  );
};
