import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { validateEmail } from '../utils/validateEmail';

export default function CheckoutForm({ promoCode }) {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const magnesFromUrl = searchParams.get('magnes');
    if (magnesFromUrl) {
      fetch(`/api/email/${magnesFromUrl}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch email');
          }
          return response.json();
        })
        .then((data) => {
          setEmail(data.email);
        })
        .catch((error) => {
          console.error('Error fetching email:', error);
        });
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { isValid, errorMessage } = validateEmail(email);
    setEmailError(errorMessage);

    if (!isValid) {
      const emailInput = document.getElementById('email');
      emailInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
      emailInput.focus();
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://katejaskolska.pl/masterclass/complete',
        receipt_email: email,
        payment_method_data: {
          billing_details: {
            email: email,
          },
        },
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
      setTimeout(() => {
        const messageElement = document.getElementById('payment-message');
        if (messageElement) {
          messageElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 100);
    } else {
      setMessage(
        'Wystąpił nieoczekiwany błąd. Odśwież stronę i spróbuj ponownie.'
      );
      setTimeout(() => {
        const messageElement = document.getElementById('payment-message');
        if (messageElement) {
          messageElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 100);
    }

    setIsLoading(false);
  };

  const options = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
    paymentMethodOrder: [
      'apple_pay',
      'google_pay',
      'card',
      'blik',
      'p24',
      'klarna',
    ],
    fields: {
      billingDetails: {
        email: 'never',
      },
    },
  };

  const regularPrice = 555.0;
  const price = promoCode === '40' ? 333.0 : regularPrice;
  const vat = (price * 0.23).toFixed(2);

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="product-details">
        <div className="product-box">
          <h3 style={{ textAlign: 'center' }}>
            Masterclass <br /> SEKRETY KOBIECEGO MAGNETYZMU
          </h3>
          <div className="price-details">
            {promoCode === '40' && (
              <div className="price-row discount-info">
                <span>Cena w przedsprzedaży</span>
                <span style={{ textDecoration: 'line-through', color: '#888' }}>
                  {regularPrice.toFixed(2)} PLN
                </span>
              </div>
            )}
            <div className="price-row">
              <span>
                {promoCode === '40' ? (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    Twoja cena ze zniżką
                    <span
                      style={{
                        background: '#d67688',
                        color: 'white',
                        padding: '2px 8px',
                        borderRadius: '12px',
                        fontSize: '0.9em',
                      }}
                    >
                      -40%
                    </span>
                  </span>
                ) : (
                  'Cena'
                )}
              </span>
              <span
                style={{
                  color: promoCode === '40' ? '#d67688' : 'inherit',
                  fontWeight: promoCode === '40' ? '600' : 'inherit',
                }}
              >
                {price.toFixed(2)} PLN
              </span>
            </div>
            <div className="price-row vat-info">
              <span>W tym VAT (23%)</span>
              <span>{vat} PLN</span>
            </div>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="form-field-container">
          <label
            htmlFor="email"
            style={{
              display: 'block',
              color: 'rgb(48, 49, 61)',
              fontFamily:
                '-apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
              fontSize: '14.88px',
              lineHeight: '17.112px',
              marginBottom: '4px',
            }}
          >
            E-mail{' '}
            <span
              style={{
                color: 'rgba(223, 27, 65, 0.6)',
                fontSize: '13px',
                fontWeight: '400',
              }}
            >
              *
            </span>
          </label>
          <input
            id="email"
            type="email"
            inputMode="email"
            autoComplete="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            onBlur={(e) => {
              const { errorMessage } = validateEmail(e.target.value);
              setEmailError(errorMessage);
            }}
            placeholder="twoj@email.com"
            style={{
              display: 'block',
              width: '100%',
              padding: '11px 12px 11px 12px',
              border: `1px solid ${emailError ? '#df1b41' : '#e0e0e0'}`,
              borderRadius: '4px',
              backgroundColor: '#ffffff',
              boxSizing: 'border-box',
              boxShadow:
                '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)',
              fontSize: '16px',
              margin: '4px 0',
            }}
            className={emailError ? 'stripe-input-error' : ''}
          />
          {emailError && <div id="email-message">{emailError}</div>}
        </div>
      </div>

      <PaymentElement id="payment-element" options={options} />
      <button
        id="submit"
        className="cta-button"
        style={{ width: '100%', marginTop: 0 }}
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : 'ZAPŁAĆ'}
        </span>
      </button>
      {message && (
        <div id="payment-message" style={{ color: '#df1b41' }}>
          {message}
        </div>
      )}
    </form>
  );
}
