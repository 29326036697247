import React, { useState } from 'react';
import { WaitingListModal } from './WaitingListModal';
import { Hero } from '../components/Hero/Hero';
import './waitingList.css';

export const WaitingList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="waiting-list-container">
      <Hero
        title="Sekrety|Kobiecego|Magnetyzmu"
        tag="Premiera 4 stycznia 2025"
      />

      <div className="content-container">
        <p className="main-message">
          Przestań Gonić za Miłością —<br />
          Dowiedz się co zrobić, <br />
          by Miłość Goniła za Tobą!
        </p>

        {!isModalOpen && (
          <button className="cta-button" onClick={() => setIsModalOpen(true)}>
            ZAPISZ SIĘ NA LISTĘ OCZEKUJĄCYCH
          </button>
        )}
      </div>

      {isModalOpen && (
        <WaitingListModal onClose={() => setIsModalOpen(false)} />
      )}
    </div>
  );
};
