import React, { useState } from 'react';
import { validateEmail } from '../utils/validateEmail';
import './WaitingListModal.css';

export const WaitingListModal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [instagram, setInstagram] = useState('');
  const [emailError, setEmailError] = useState('');
  const [instagramError, setInstagramError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [consentError, setConsentError] = useState('');

  // Move addWobble to component level
  const addWobble = (element) => {
    if (!element) return; // Guard clause for null elements
    element.classList.remove('wobble-error'); // Remove class first
    void element.offsetWidth; // Trigger reflow to restart animation
    element.classList.add('wobble-error');
    setTimeout(() => {
      element.classList.remove('wobble-error');
    }, 400);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;

    setEmailError('');
    setConsentError('');

    if (!marketingConsent) {
      setConsentError('Zgoda jest wymagana');
      const consentElement = document.querySelector('.checkbox-container');
      addWobble(consentElement);
      hasError = true;
    }

    // Validate email
    const { isValid, errorMessage } = validateEmail(email);
    if (!isValid) {
      setEmailError(errorMessage);
      const emailInput = document.getElementById('email');
      addWobble(emailInput);
      hasError = true;
    }

    // Simplified Instagram validation
    if (!instagram.trim()) {
      setInstagramError('Instagram jest wymagany.');
      const instagramInput = document.getElementById('instagram');
      addWobble(instagramInput);
      hasError = true;
    }

    if (hasError) {
      setTimeout(() => {
        const elements = document.querySelectorAll('.error');
        elements.forEach((element) => {
          addWobble(element);
        });
      }, 0);
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch('/api/waiting-list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          instagram,
          marketingConsent,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setIsSuccess(true);
    } catch (error) {
      console.error('Error:', error);
      setEmailError('Wystąpił błąd. Spróbuj ponownie później.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateInstagramHandle = (handle) => {
    const regex = /^[a-zA-Z0-9_.]{1,30}$/;
    return regex.test(handle.trim());
  };

  const handleInstagramChange = (e) => {
    const value = e.target.value;
    setInstagram(value);
    setInstagramError('');

    const handle = value.trim();
    if (handle && !validateInstagramHandle(handle)) {
      setInstagramError('Nieprawidłowa nazwa użytkownika Instagram');
    }
  };

  return (
    <div
      className="modal-overlay"
      onClick={(e) => {
        e.stopPropagation();
        if (isSuccess) {
          onClose();
        }
      }}
    >
      <div
        className="modal-content stripe-checkout"
        onClick={(e) => e.stopPropagation()}
      >
        {!isSuccess ? (
          <form
            onSubmit={handleSubmit}
            className="waiting-list-form"
            noValidate
          >
            <h2>Zapisz się na listę oczekujących</h2>

            <div className="form-row">
              <div className="form-field-container">
                <label htmlFor="email" className="form-label">
                  E-mail <span className="required-asterisk">*</span>
                </label>
                <input
                  id="email"
                  type="email"
                  autocomplete="email"
                  value={email}
                  noValidate
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError('');
                  }}
                  onBlur={(e) => {
                    const { errorMessage } = validateEmail(e.target.value);
                    setEmailError(errorMessage);
                  }}
                  placeholder="twoj@email.com"
                  className={`form-input ${emailError ? 'error' : ''}`}
                />
                {emailError && <div id="email-message">{emailError}</div>}
              </div>
            </div>

            <div className="form-row">
              <div className="form-field-container">
                <label htmlFor="instagram" className="form-label">
                  Instagram <span className="required-asterisk">*</span>
                </label>
                <div className="input-wrapper">
                  <input
                    id="instagram"
                    type="text"
                    autoCapitalize="none"
                    value={instagram}
                    onChange={handleInstagramChange}
                    placeholder="twoj.instagram"
                    className={`form-input ${instagramError ? 'error' : ''}`}
                  />
                </div>
                {instagramError && (
                  <div id="email-message">{instagramError}</div>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="checkbox-container">
                <label
                  className={`checkbox-label ${!marketingConsent && consentError ? 'error' : ''}`}
                >
                  <input
                    type="checkbox"
                    checked={marketingConsent}
                    onChange={(e) => {
                      setMarketingConsent(e.target.checked);
                      setConsentError('');
                    }}
                  />
                  <span>
                    Wyrażam zgodę na otrzymywanie informacji marketingowych
                    drogą elektroniczną.
                  </span>
                </label>
              </div>
            </div>
            <button
              className="cta-button"
              type="submit"
              id="submit"
              style={{ width: '100%' }}
            >
              ZAPISZ SIĘ NA LISTĘ OCZEKUJĄCYCH
            </button>
          </form>
        ) : (
          <div className="success-animation">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h2>Dziękuję!</h2>
            <p>Zostałaś zapisana na listę oczekujących</p>
            <p>
              W ciągu 24 godzin otrzymasz ode mnie wiadomość DM na Instagramie z
              dodatkowymi informacjami i następnymi krokami
            </p>

            <p className="success-message">Możesz zamknąć to okno</p>
          </div>
        )}
      </div>
    </div>
  );
};
